import { Titlecard, Sectioncard, Footnote } from "../../../../src/components/main";
import Fragment from "../../../../src/components/fragment";
import { TeX, RefTeX } from "../../../../src/components/tex";
import { Arrow } from "../../../../talks/advancement/components";
import { procon, pro, con } from "../../../../talks/heston/index.module.css";
import Chart from "../../../../talks/heston/chart";
import screen from "../../../../talks/heston/screen.png";
import * as React from 'react';
export default {
  Titlecard,
  Sectioncard,
  Footnote,
  Fragment,
  TeX,
  RefTeX,
  Arrow,
  procon,
  pro,
  con,
  Chart,
  screen,
  React
};