import React from 'react';
import ReactDOM from 'react-dom';
import fp from 'lodash/fp';

import { titlecard, sectioncard, footnote } from './index.module.css';
import useDeck from '../../hooks/use-deck';

function Titlecard({ title, date, author, email, children }) {
  return (
    <div className={ titlecard }>
      <h1>{ title }</h1>
      <p>
        { date }
        <br/>
        { author }
        <br/>
        <a href={ `mailto:${email}` }>{ email }</a>
        <br/><br/>
        <span>{ children }</span>
      </p>
    </div>
  );
}
export { Titlecard };

function Sectioncard({ title, subtitle }) {
  return (
    <div className={ sectioncard }>
      <span />
      <h1>{ title }</h1>
      <h2>{ subtitle }</h2>
      <span />
    </div>
  );
}
export { Sectioncard };

const footnoteDom = (
  typeof document !== 'undefined'
  ? document.getElementById('portal')
  : null
);

function Footnote({ index, ...props }) {
  // create a visibility variable from the deck state
  const step = useDeck(
    state => fp.get(`fragments[${state.index}][${state.step - 1}]`)(state)
  );
  const visible = !Number.isFinite(index) || step === index;

  // our render element
  const elm = <div className={ footnote }><div { ...props }/></div>

  // render the footnote
  return (
    footnoteDom && visible
    ? ReactDOM.createPortal(elm, footnoteDom)
    : null
  );
}
export { Footnote }
