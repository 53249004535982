import React from 'react';
import { Vector3 } from 'three';
import { PerspectiveCamera } from '@react-three/drei/PerspectiveCamera';
import { OrbitControls } from '@react-three/drei/OrbitControls';
import { Line } from '@react-three/drei/Line';

import Canvas from '../../src/components/canvas';
import { TeX } from '../../src/components/tex';

export default function Traces() {
  // state management
  const [selected, setSelected] = React.useState('x');
  const [val, setVal] = React.useState(0);

  // little axis logic
  const right = selected === 'y' ? 'x' : (selected === 'z' ? 'x' : 'y');
  const up = selected === 'y' ? 'z' : (selected === 'z' ? 'y' : 'z');

  // render
  return (
    <div style={{ display: 'flex', width: '700px', margin: '0 auto', flexDirection: 'column' }}>
      <Radio selected={ selected } setSelected={ setSelected } />
      <Slider selected={ selected } val={ val } setVal={ setVal } />
      <div style={{ display: 'flex' }}>
        <div style={{ position: 'relative' }}>
          <span style={{ position: 'absolute', top: '83px', left: '250px' }}><TeX>{ right }</TeX></span>
          <span style={{ position: 'absolute', top: '0px', left: '164px' }}><TeX>{ up }</TeX></span>
          <span style={{ position: 'absolute', top: '278px', left: '250px' }}><TeX>{ right }</TeX></span>
          <span style={{ position: 'absolute', top: '195px', left: '164px' }}><TeX>{ up }</TeX></span>
          <Canvas dims={{ width: 300, height: 200 }} style={{ boxSizing: 'border-box', border: '2px solid var(--main)', }}>
            <Axes selected={ selected } />
            <Curve selected={ selected } val={ val } color="blue" k={ 0 } linewidth={ 4 } />
            <Camera selected={ selected } />
          </Canvas>
          <Canvas dims={{ width: 300, height: 200 }} 
            style={{ 
              boxSizing: 'border-box', 
              borderLeft: '2px solid var(--main)',
              borderRight: '2px solid var(--main)',
              borderBottom: '2px solid var(--main)',
            }}>
            <Axes selected={ selected } />
            <Curve selected={ selected } val={ 0 } color="green" k={ 0 } />
            {
              [1, 2, 3, 4, 5].flatMap(n => [
                <Curve selected={ selected } val={ n } key={ 2 * n } color="green" k={ 0 } />,
                <Curve selected={ selected } val={ -n } key={ 2 * n + 1 } color="green" k={ 0 } />,
              ])
            }
            <Curve selected={ selected } val={ val } color="blue" k={ 0 } linewidth={ 4 } />
            <Camera selected={ selected } />
          </Canvas>
        </div>
        <div>
          <Canvas dims={{ width: 400, height: 400 }} 
            style={{ 
              boxSizing: 'border-box', 
              borderTop: '2px solid var(--main)',
              borderRight: '2px solid var(--main)',
              borderBottom: '2px solid var(--main)',
            }}>
            <ambientLight color="white" intensity={ 0.5 } />
            <PerspectiveCamera 
              makeDefault  
              position={ [10, 10, 10] }
              up={ [0, 0, 1]  }>
              <pointLight color="white" intensity={ 0.5 } />
            </PerspectiveCamera>
            <Plane selected={ selected } val={ val } />
            <axesHelper args={ [5] } />
            <OrbitControls enableDamping={ false } />
            <Hyperbola color="yellow" />
            <Curve selected={ selected } val={ val } color="blue" k={ val } linewidth={ 4 } />
          </Canvas>
        </div>
      </div>
    </div>
  );
}

function Radio({ selected, setSelected }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <input 
          type="radio" 
          id="x" 
          checked={ selected !== 'y' && selected !== 'z' }
          onChange={ e => setSelected(e.target.id) } />
        <label style={{ marginLeft: '0.25em' }} htmlFor="x"><TeX>x</TeX>-trace</label>
      </div>
      <div>
        <input type="radio" id="y" checked={ selected === 'y' } 
          onChange={ e => setSelected(e.target.id) } />
        <label style={{ marginLeft: '0.25em' }} htmlFor="y"><TeX>y</TeX>-trace</label>
      </div>
      <div>
        <input type="radio" id="z" checked={ selected === 'z' } 
          onChange={ e => setSelected(e.target.id) } />
        <label style={{ marginLeft: '0.25em' }} htmlFor="z"><TeX>z</TeX>-trace</label>
      </div>
    </div>
  );
}

function Slider({ selected, val, setVal }) {
  return (
    <div style={{ margin: '0.5em 0', display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <input 
          type="range" 
          min={ -5 } 
          max={ 5 } 
          step={ 0.1 } 
          value={ val }
          style={{ width: '200px' }} 
          id="val"
          onChange={ e => setVal(+e.target.value) }
        />
      <label style={{ marginLeft: '0.25em' }} htmlFor="val"><TeX>{ `${selected} = ${val}` }</TeX></label>
      </div>
      <div style={{ width: '350px', textAlign: 'center' }}>
        <TeX style={{ color: 'red' }}>
          {
            selected === 'y'
            ? `x^2 + z^2 = 1 + (${val.toFixed(1)})^2`
            : `${selected === 'z' ? 'x' : 'z'}^2 - y^2 = 1 - (${val.toFixed(1)})^2`
          }
        </TeX>
      </div>
    </div>
  );
}

const OP = (selected, val) => {
  const coord = selected === 'y' ? 1 : (selected === 'z' ? 2 : 0);
  return p => [...p.slice(0, coord), val, ...p.slice(coord)];
};

function Plane({ selected, val }) {
  const position = OP(selected, val)([0, 0]);
  const rotation = (
    selected === 'y'
    ? [Math.PI/2, 0, 0]
    : (selected === 'z'
      ? [0, 0, 0]
      : [0, Math.PI/2, 0]
    )
  );
  return (
    <mesh position={ position } rotation={ rotation }>
      <planeBufferGeometry args={ [10, 10, 1, 1] } />
      <meshStandardMaterial color="#ee0088" side={ 2 } transparent={ true } opacity={ 0.5 } />
    </mesh>
  );
}

function Axes({ selected }) {
  const op = OP(selected, 0);
  return (
    <object3D>
      <Line points={ [[-5, 0], [5, 0]].map(op) } />
      <Line points={ [[0, -5], [0, 5]].map(op) } />
      {
        [1, 2, 3, 4, 5].flatMap((_, n) => [
          <Line points={ [[n, -0.25], [n, 0.25]].map(op) } key={ n * 4 }/>,
          <Line points={ [[-n, -0.25], [-n, 0.25]].map(op) } key={ n * 4 + 1 }/>,
          <Line points={ [[-0.25, n], [0.25, n]].map(op) } key={ n * 4 + 2 }/>,
          <Line points={ [[-0.25, -n], [0.25, -n]].map(op) } key={ n * 4 + 3 }/>,
        ])
      }
    </object3D>
  );
}

function Camera({ selected }) {
  // create a ref for the camera
  const ref = React.useRef();

  // change the view
  React.useEffect(() => {
    if (ref.current) {
      ref.current.lookAt(new Vector3(0, 0, 0));
    }
  }, [ref, selected]);

  // calculate props for the PerspectiveCamera component
  const up = selected === 'z' ? [0, 1, 0] : [0, 0, 1];
  const distance = 12;
  const position = (
    selected === 'y'
    ? [0, distance, 0]
    : (selected === 'z' ? [0, 0, distance] : [distance, 0, 0])
  );
  return (
    <PerspectiveCamera up={ up } position={ position } ref={ ref } makeDefault/>
  );
}

function Curve({ selected, val, color, k, ...props }) {
  return (
    selected === 'y'
    ? <CurveY val={ val } color={ color } k={ k } { ...props } />
    : <CurveXZ selected={ selected } val={ val } color={ color } k={ k } { ...props }/> 
  );
}

const circle = r => (t => [
  r * Math.cos(2 * Math.PI * t),
  r * Math.sin(2 * Math.PI * t),
]);

const hyperbolaH = (side, fac) => (t => [
  side * fac / Math.cos(t),
  fac * Math.tan(t),
]);

const hyperbolaV = (side, fac) => (t => [
  fac * Math.tan(t),
  side * fac / Math.cos(t),
]);

const N = 100;

function CurveY({ val, color, k, ...props }) {
  const radius = Math.sqrt(1 + val * val);
  const op = OP('y', k);
  const param = t => op(circle(radius)(t));
  const points = Array.from({ length: N + 1 }).map((_, i) => param(i / N));
  return <Line color={ color } points={ points } { ...props } />;
}

function CurveXZ({ selected, val, color, k, ...props }) {
  const op = OP(selected, k);
  const valSqr = val * val;
  const fac = Math.sqrt(Math.abs(1 - valSqr));
  const ts = Array.from({ length: N + 1 }).map((_, i) => i/N - 0.5);
  const scale = Math.min(2 * Math.atan(5 / fac), 2 * Math.acos(fac / 5));
  if (valSqr === 1) {
    return <>
      <Line color={ color } points={ [[-5, -5], [5, 5]].map(op) } { ...props } />
      <Line color={ color } points={ [[-5, 5], [5, -5]].map(op) } { ...props } />
    </>;
  } else if ((valSqr < 1 && selected === 'z') || (valSqr > 1 && selected === 'x')) {
    const pointsL = ts.map(t => op(hyperbolaH(-1, fac)(scale * t)));
    const pointsR = ts.map(t => op(hyperbolaH(1, fac)(scale * t)));
    return <>
      <Line color={ color } points={ pointsL } { ...props } />
      <Line color={ color } points={ pointsR } { ...props } />
    </>
  } else {
    const pointsD = ts.map(t => op(hyperbolaV(-1, fac)(scale * t)));
    const pointsU = ts.map(t => op(hyperbolaV(1, fac)(scale * t)));
    return <>
      <Line color={ color } points={ pointsD } { ...props } />
      <Line color={ color } points={ pointsU } { ...props } />
    </>;
  }
}

function Hyperbola({ color }) {
  const p = (u, v, w) => {
    const y = 10 * v - 5;
    const r = Math.sqrt(1 + y * y);
    const t = Math.PI * 2 * u;
    w.set(r * Math.cos(t), y, r * Math.sin(t));
  };

  return <>
    <mesh>
      <parametricBufferGeometry args={ [p, 100, 100] } attach="geometry" />
      <meshStandardMaterial color="yellow" attach="material" side={ 2 } />
    </mesh>
    {
      [1, 2, 3, 4, 5].flatMap(n => [
        <Curve selected="x" val={ n } key={ 6 * n  } color="green" k={ n } />,
        <Curve selected="x" val={ -n } key={ 6 * n + 1 } color="green" k={ -n } />,
        <Curve selected="y" val={ n } key={ 6 * n + 2 } color="green" k={ n } />,
        <Curve selected="y" val={ -n } key={ 6 * n + 3 } color="green" k={ -n } />,
        <Curve selected="z" val={ n } key={ 6 * n + 4} color="green" k={ n } />,
        <Curve selected="z" val={ -n } key={ 6 * n + 5 } color="green" k={ -n } />,
      ])
    }
  </>;
}
