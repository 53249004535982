import { VictoryTheme } from 'victory';

// change font in theme
function changeFamily(obj) {
  return Object.keys(obj).reduce((acc, key) => ({
    ...acc,
    [key]: (
      key === 'fontFamily' 
      ? 'inherit' 
      : (
        (typeof obj[key] === 'object' && obj !== null)
        ? changeFamily(obj[key])
        : obj[key]
      )
    ),
  }), {});
}

export default changeFamily(VictoryTheme.material);
