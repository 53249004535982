import { Titlecard, Sectioncard, Footnote } from "../../../../src/components/main";
import Fragment from "../../../../src/components/fragment";
import { TeX, RefTeX } from "../../../../src/components/tex";
import Chart1 from "../../../../talks/advancement/samples/chart1";
import Chart2 from "../../../../talks/advancement/samples/chart2";
import { BrownianMotion } from "../../../../talks/advancement/samples/chart3";
import { Bessel } from "../../../../talks/advancement/samples/chart3";
import { OrnsteinUhlenbach } from "../../../../talks/advancement/samples/chart3";
import { CIR } from "../../../../talks/advancement/samples/chart3";
import { Poisson } from "../../../../talks/advancement/samples/chart3";
import { Levy } from "../../../../talks/advancement/samples/chart3";
import { Branching } from "../../../../talks/advancement/samples/chart3";
import { Hawkes } from "../../../../talks/advancement/samples/chart3";
import { MonteCarlo } from "../../../../talks/advancement/samples/chart4.js";
import { ImportanceSampling } from "../../../../talks/advancement/samples/chart4.js";
import AsymptoticRegime from "../../../../talks/advancement/samples/chart5";
import * as React from 'react';
export default {
  Titlecard,
  Sectioncard,
  Footnote,
  Fragment,
  TeX,
  RefTeX,
  Chart1,
  Chart2,
  BrownianMotion,
  Bessel,
  OrnsteinUhlenbach,
  CIR,
  Poisson,
  Levy,
  Branching,
  Hawkes,
  MonteCarlo,
  ImportanceSampling,
  AsymptoticRegime,
  React
};