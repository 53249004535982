import create from 'zustand';
import fp from 'lodash/fp';

import { modes } from '../constants';

const useDeck = create(set => {
  // alias simple value+reducer creators with lodash
  const value = (name, init) => ({ 
    [name]: init,
    ['set' + fp.upperFirst(name)]: value => set(fp.set(name)(value)),
  });

  // create the simple parts of the store
  const simple = {
    ...value('slug', null),
    ...value('index', null),
    ...value('length', null),
    ...value('print', false),
    ...value('width', 960),
    ...value('height', 700),
  };

  // create the navigation part of the store
  const setFragments = value => set(fp.set('fragments')(value));
  const setStep = value => set(fp.set('step')(value));
  const fragments = {
    fragments: [], 
    setFragments, 
    step: 0, 
    setStep,
  };

  // create the mode part of the store
  const mode = {
    ...value('mode', modes.normal),
    toggleMode: next => set(state => (
      state.mode === next
      ? { ...state, mode: modes.normal }
      : { ...state, mode: next }
    )),
  };

  // create the metadata part of the store
  const metadata = {
    metadata: {},
    register: (i, k, v) => set(fp.set(`metadata.${i}.${k}`)(v)),
  };

  // return the store
  return { 
    ...simple, 
    ...fragments,
    ...mode,
    ...metadata,
  };
});

export default useDeck;
