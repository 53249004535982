import React from 'react';
import fp from 'lodash/fp';
import shallow from 'zustand/shallow';

import useDeck from '../hooks/use-deck';

export default function Fragment({
  only,
  index,
  style = {}, 
  ...props 
}) {
  const [step, print] = useDeck(
    state => [
      fp.get(`fragments[${state.index}][${state.step - 1}]`)(state),
      state.print,
    ],
    shallow,
  );
  const visibility = (
    print || (Number.isFinite(step) && (only ? index === step : index <= step))
    ? 'visible'
    : 'hidden'
  );
  return <span style={{ ...style, visibility }} { ...props } />;
}
