import React from 'react';
import { VictoryChart, VictoryLine, VictoryAxis } from 'victory';
import fp from 'lodash/fp';

import path from './data.json';
import theme from '../../src/assets/main-theme';
import useDeck from '../../src/hooks/use-deck';

// parse the data
const allData = path.map((y, i) => ({ x: i/path.length, y }));
const dataStart = allData.filter(d => d.x <= 0.9);
const dataEnd = allData.filter(d => d.x > 0.9).map(({ x, y }) => ({ 
  x, 
  y: y + 18 * (x - 0.9),
}));

// strike
const strike = 4.5;


// view constants
const width = 800;
const height = 300;

export default function Chart() {
  // use the deck to see which fragment we are on
  const step = useDeck(
    state => fp.get(`fragments[${state.index}][${state.step - 1}]`)(state)
  );
  const index = Number.isFinite(step) ? step + 1 : 0;

  // set the data according to the fragment
  let line;
  if (index === 0) {
    line = dataStart;
  } else if (index === 1) {
    line = allData;
  } else {
    line = [...dataStart, ...dataEnd];
  }

  // domain
  const domain = index <= 2 ? [0, 1.1] : [0.9, 1.0] 
  const ticks = index <= 2 ? [0, 0.9, 1.0] : [0.9, 1.0] 

  // render
  return (
    <div style={{ width: `${width}px`, height: `${height}px`, display: 'block', margin: '0 auto' }}>
      <VictoryChart
        width={ width }
        height={ height }
        theme={ theme }
        animate={ { duration: 250 } }
        domain={{ x: domain, y: [0, 5] }}
        padding={{ top: 10, right: 10, bottom: 50, left: 10 }}>
        <VictoryAxis 
          tickValues={ ticks } 
          tickFormat={ t => t === 0 ? '0' : (t === 0.9 ? 't' : 'T') }
          domain={ domain }/>
        <VictoryAxis 
          dependentAxis
          tickValues={ [] }
          tickFormat={ () => '' }
          domain={ [0, 5] } />
        <VictoryLine data={ line } />
        <VictoryLine 
          data={ [{ x: 0, y: strike }, { x: 1, y: strike }] } 
          style={{ data: { stroke: 'var(--alt)' } }}/>
      </VictoryChart>
    </div>
  );
}
