import React from 'react';
import { useCanvas } from 'react-three-fiber';
import { WebGLRenderer, WebGL1Renderer } from 'three'

/**
 * Hack the Canvas element to not have resizes;
 * everything copied pretty directly from react-three-fiber source
 */
function Content({ children, setEvents, container, renderer, effects, ...props }) {
  // Create renderer
  const [gl] = React.useState(renderer)
  if (!gl) console.warn('No renderer created!')

  // Mount and unmount management
  React.useEffect(() => {
    if (effects) effects(gl, container)
  }, [container, effects, gl])

  // Init canvas, fetch events, hand them back to the wrapping div
  const events = useCanvas({ ...props, children, gl })
  React.useEffect(() => {
    setEvents(events)
  }, [events, setEvents])
  return null
}

const Container = React.memo(props => { 
  const {
    renderer,
    effects,
    children,
    vr,
    webgl1,
    concurrent,
    shadowMap,
    colorManagement,
    orthographic,
    invalidateFrameloop,
    updateDefaultCamera,
    noEvents,
    gl,
    camera,
    raycaster,
    pixelRatio,
    onCreated,
    onPointerMissed,
    preRender,
    style,
    size, // added this in to remove react-use-measure: only need width and height
    ...restSpread
  } = props;

  const containerRef = React.useRef();
  // onGotPointerCaptureLegacy is a fake event used by non-web targets to simulate poinzter capture
  const [{ onGotPointerCaptureLegacy, ...events }, setEvents] = React.useState({});

  // Flag view ready once it's been measured out
  const readyFlag = React.useRef(false);
  const ready = React.useMemo(() => (readyFlag.current = readyFlag.current || (!!size.width && !!size.height)), [size])
  const state = React.useMemo(() => ({ 
    size, 
    forceResize: () => {}, 
    setEvents, 
    container: containerRef.current,
  }), [size]);

  // Allow Gatsby, Next and other server side apps to run. Will output styles to reduce flickering.
  if (typeof window === 'undefined')
    return (
      <div style={{ ...style, width: size.width, height: size.height }} {...restSpread}>
        {preRender}
      </div>
    )

  // Render the canvas into the dom
  return (
    <div 
      ref={ containerRef } 
      style={{ ...style, width: size.width, height: size.height }} 
      {...events} 
      {...restSpread}
    >
      {preRender}
      {ready && <Content {...props} {...state} />}
    </div>
  )
});

const Canvas = React.memo(function Canvas({ children, dims, ...props }) {
  const canvasRef = React.useRef()
  const renderer = props.webgl1 ? WebGL1Renderer : WebGLRenderer;
  const [size, setSize] = React.useState({ width: 0, height: 0 });
  React.useEffect(() => {
    if (canvasRef) {
      const { width, height } = dims || {};
      setSize({ width: width || 500, height: height || 300 });
    }
  }, [canvasRef, setSize, dims]);
  return (
    <Container
      {...props}
      renderer={() => {
        if (canvasRef.current) {
          const params = { antialias: true, alpha: true, ...props.gl };
          const temp = new renderer({
            powerPreference: 'high-performance',
            //stencil: false,
            //depth: false,
            canvas: canvasRef.current,
            ...params,
          });
          return temp;
        }
      }}
      preRender={<canvas ref={canvasRef} style={{ display: 'block' }} />}
      size={ size }
    >
      {children}
    </Container>
  );
});

export default Canvas;
