import React from 'react';
import renderMathInElement from 'katex/dist/contrib/auto-render.js';
import Tex from '@matejmazur/react-katex';

import katexConfig from '../katex';

function TeX(props) {
  return (
    props.block
    ?  <Tex settings={ katexConfig } { ...props }>{ props.children }</Tex>
    : <Tex settings={ katexConfig } { ...props }>{ props.children }</Tex>
  );
}

function RefTeX(props) {
  // create a ref of the element we intend to parse
  const ref = React.useRef();

  // parse the katex
  React.useEffect(() => {
    renderMathInElement(
      ref.current, 
      { 
        ...katexConfig,
        delimiters: [
          {left: "$$", right: "$$", display: true},
          {left: "$", right: "$", display: false},
        ]
      },
    );
  }, [ref, props.children]);

  // return a referenced span for the body
  return <span ref={ref} {...props} />;
}

export { TeX, RefTeX };
